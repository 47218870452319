<template>
  <div class="entete">
    <div class="box-setting-header">
      <div class="title ml-4 mt-2">Gérer des configurations Mensuelles</div>
      <div>
        <div class="error-message ml-3">
          <div v-if="getConfErrors" class="error">
            <ul v-if="Array.isArray(getConfErrors)" class="mb-0">
              <li v-for="(e, index) in getConfErrors" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getConfErrors }}</div>
          </div>
        </div>
      </div>
      <div>
        <b-button
          v-if="checkPermission('GCMACM')"
          v-show="
            selectedMenu == 'client' ||
              selectedMenu == 'bien' ||
              selectedMenu == 'service'
          "
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addConfComptaRh
          ><font-awesome-icon icon="plus" /> Ajouter une configuration</b-button
        >
        <b-modal
          id="addConfComptaRh"
          ref="addConfComptaRh"
          title="Nouvelle configuration"
          no-close-on-backdrop
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap custom-modal-size"
        >
          <div class="hader mb-2">
            <div class="titleSetting">Nouvelle configuration</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('addConfComptaRh')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="handleSubmitConfiguration"
                class="form-modal-custom-style"
              >
                <div class="ModelCol">
                  <b-form-group
                    label="Type"
                    label-for="type"
                    class="input-modal-champ col-4 p-0"
                  >
                    <b-form-select
                      v-model="configurationToAdd.type"
                      @change="handleErrorConfig"
                      id="type"
                      required
                      :options="ListType"
                      text-field="text"
                      value-field="value"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Liste des configurations"
                    label-for="Nom de produit"
                    class="input-modal-champ col-4 p-0"
                  >
                    <b-form-select
                      v-model="configurationToAdd.service_bien"
                      @change="handleErrorConfig"
                      id="Liste des configurations"
                      required
                      :options="computedListConfig"
                      text-field="text"
                      value-field="value"
                      class="b-form-select-raduis"
                      :disabled="disable"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Jour de création"
                    label-for="Nom-de-produit"
                    class="input-modal-champ col-4 p-0"
                  >
                    <b-form-select
                      v-model="configurationToAdd.jour_creation"
                      id="Nom-de-produit"
                      :options="computedListJourCreation"
                      text-field="text"
                      value-field="value"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="ModelCol">
                  <b-form-group
                    label="Societé"
                    label-for="Nom de produit"
                    class="input-modal-champ col-3 p-0"
                  >
                    <b-form-select
                      id="vendeur"
                      v-model="configurationToAdd.vendeur_id"
                      required
                      :options="getSettingFilialesTh"
                      @change="handleErrorConfig"
                      text-field="name"
                      value-field="id"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Client"
                    label-for="Nom de produit"
                    class="input-modal-champ col-3 p-0"
                  >
                    <b-form-select
                      id="client"
                      v-model="configurationToAdd.acheteur_id"
                      required
                      :options="getSettingFilialesTh"
                      text-field="name"
                      value-field="id"
                      @change="handleErrorConfig"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Montant Hors Taxes"
                    label-for="PU HT"
                    class="input-modal-champ col-2 p-0"
                  >
                    <b-form-input
                      @input="calculTTC"
                      id="montant"
                      v-model="configurationToAdd.montant"
                      min="0"
                      step="0.00001"
                      required
                      type="number"
                    ></b-form-input>
                  </b-form-group>

                  <div role="group" class="form-group col-2 input-modal-champ">
                    <label for="swift-template-input" class="d-block"
                      >TVA % *
                      <button
                        type="button"
                        v-b-modal.addtvaModal
                        class="ml-2 btn-add-tva-unite"
                      >
                        <font-awesome-icon
                          icon="plus"
                          class="icon-plus-tva-unite"
                        /></button
                    ></label>
                    <b-form-select
                      id="tva"
                      v-model="configurationToAdd.tva"
                      required
                      :options="getAllTvas"
                      @input="calculTTC"
                      class="b-form-select-raduis"
                      text-field="valeur"
                      value-field="valeur"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="TVA %"
                    ></b-form-select>
                  </div>
                  <b-form-group
                    label="Montant TTC"
                    label-for="PU HT"
                    class="input-modal-champ col-2 p-0"
                  >
                    <b-form-input
                      :disabled="true"
                      id="montant"
                      v-model="montant_ttc"
                      required
                      type="number"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol">
                  <b-form-group
                    label="Date début"
                    label-for="Début de configuration"
                    class="input-modal-champ"
                  >
                    <date-picker
                      value-type="format"
                      v-model="configurationToAdd.date_debut"
                      type="date"
                      required
                      :clear-button="true"
                      :disabled-date="disabledStartDate"
                      @change="handleErrorConfig"
                    ></date-picker>
                  </b-form-group>
                  <b-form-group
                    label="Date fin"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                  >
                    <date-picker
                      v-model="configurationToAdd.date_fin"
                      value-type="format"
                      type="date"
                      :clear-button="true"
                      :disabled-date="disabledEndDate"
                      @change="handleErrorConfig"
                    ></date-picker>
                  </b-form-group>
                  <b-form-group
                    label="Fréquence de facturation"
                    label-for="fréquence de facturation"
                    class="input-modal-champ"
                  >
                    <b-form-select
                      id="frequance"
                      v-model="configurationToAdd.frequence"
                      required
                      :options="freqOptions"
                      text-field="text"
                      value-field="value"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="ModelCol">
                  <b-form-group
                    label="Objet"
                    label-for="Objet"
                    class="input-modal-champ"
                  >
                    <b-form-textarea
                      id="description"
                      row="3"
                      v-model="configurationToAdd.objet"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
                <div class="ModelCol">
                  <b-form-group
                    label="Description"
                    label-for="Description"
                    class="input-modal-champ"
                  >
                    <b-form-textarea
                      id="description"
                      row="3"
                      v-model="configurationToAdd.description"
                    ></b-form-textarea>
                  </b-form-group>
                </div>

                <div class="actionModel">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul
                        v-if="Array.isArray(error)"
                        class="mb-0"
                        style="list-style-type: none"
                      >
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <div class="messageError">
                    <div v-if="errorDuplicate" class="errorConfig">
                      <ul v-if="Array.isArray(errorDuplicate)">
                        <li v-for="(e, index) in errorDuplicate" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ errorDuplicate }}</div>
                    </div>
                  </div>
                  <b-button
                    type="submit"
                    class="button-valide-style"
                    :disabled="validateToAddconf"
                  >
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
        <b-modal
          ref="addtvaModal"
          id="addtvaModal"
          no-close-on-backdrop
          :hide-footer="true"
          @hidden="resetModalTVA"
          title="Ajouter tva"
          modal-class="cutsom-modal-bootstrap"
        >
          <b-form-group label="Tva" label-for="unite" class="input-modal-champ">
            <b-form-input
              id="tva"
              v-model="tvaToAdd"
              required
              type="number"
              autocomplete="off"
              min="0"
              oninput="validity.valid||(value < 0 || value.length==0? value = '' : value =value);"
            ></b-form-input>
          </b-form-group>
          <div class="error-message d-flex justify-content-center">
            <div class="error">
              <div>{{ errorTvaUnite }}</div>
            </div>
          </div>
          <div class="form-modal-custom-style mt-2">
            <div class="actionModel">
              <b-button class="button-valide-style" @click="addTav('tva')">
                <span>
                  Valider
                  <div v-if="loadingTvaUnite" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </div>
        </b-modal>
      </div>
    </div>

    <div class="sub-menu-simple">
      <div class="nav-bar">
        <div class="menu-links">
          <div
            class="menu-link"
            @click="clickMenu('client')"
            v-if="checkPermission('GCM')"
          >
            <router-link class="menu-item" to="/setting/configurationMensuel">
              Configurations par sociétés
            </router-link>
          </div>
          <div
            class="menu-link"
            @click="clickMenu('bien')"
            v-if="checkPermission('GCMCPB')"
          >
            <router-link
              class="menu-item"
              to="/setting/configurationMensuel/gestion-configuration-bien-mensuel"
            >
              Configurations par Biens
            </router-link>
          </div>
          <div
            class="menu-link"
            @click="clickMenu('service')"
            v-if="checkPermission('GCMCPS')"
          >
            <router-link
              class="menu-item"
              to="/setting/configurationMensuel/gestion-configuration-service-mensuel"
            >
              Configurations par services
            </router-link>
          </div>
          <b-button
            size="sm"
            class="button-export-style ml-2 mr-2"
            title="Filtres"
            id="popover-target-1"
          >
            <font-awesome-icon icon="filter" />
          </b-button>
          <div v-if="getConfLoading" class="loading ml-2">
            <div class="spinner-border loadingFetch" role="status"></div>
          </div>
          <b-popover
            triggers="focus"
            target="popover-target-1"
            placement="top"
            custom-class="my-custom-class-popover-filter"
          >
            <filter-component label="Société" classLabel="w-100-px">
              <template v-slot:body>
                <multiselect
                  v-model="filtre.vendeur"
                  :multiple="true"
                  label="text"
                  :options="computedGetVendeurs"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  @input="changeVendeurFiltre"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length
                      }}{{
                        values.length == 1
                          ? ' Société'
                          : values.length > 1
                          ? ' Sociétés'
                          : ''
                      }}</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component label="Client" classLabel="w-100-px">
              <template v-slot:body>
                <multiselect
                  v-model="filtre.acheteur"
                  :multiple="true"
                  :options="computedGetAcheteurs"
                  label="text"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  @input="changeAcheteurFiltre"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Acheteur</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Acheteurs</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component label="Periode" classLabel="w-100-px">
              <template v-slot:body>
                <multiselect
                  v-model="filtre.period"
                  :options="periodOptions"
                  label="text"
                  track-by="value"
                  class="customSelectSingleFilter mt-2 mb-2"
                  :showLabels="false"
                  :searchable="false"
                  placeholder="sélectionner"
                  @input="changePeriodFiltre"
                >
                </multiselect
              ></template> </filter-component
          ></b-popover>
        </div>
      </div>

      <div class="body">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import filterComponent from '../../ThProject/frais/component/filterComponent.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      tvaToAdd: null,
      errorTvaUnite: null,
      loadingTvaUnite: false,
      selectedMenu: 'client',
      configurationToAdd: {
        type: null,
        service_bien: null,
        vendeur_id: null,
        acheteur_id: null,
        montant: 0,
        tva: 0,
        description: null,
        objet: null,
        date_debut: null,
        date_fin: null,
        frequence: 'mensuel'
      },
      filtre: {
        vendeur: [],
        acheteur: [],
        period: null
      },
      montant_ttc: 0,
      error: [],
      loading: false,
      disable: true,
      errorDuplicate: null,
      validateToAddconf: false,
      freqOptions: [{ text: 'Mensuel', value: 'mensuel' }],
      periodOptions: [
        { text: 'À venir', value: 'avenir' },
        { text: 'Encours', value: 'encours' },
        { text: 'Expiré', value: 'expiree' }
      ]
    };
  },
  computed: {
    ...mapGetters([
      'getConfLoading',
      'checkPermission',
      'getConfErrors',
      'getAllTvas',
      'getSettingFilialesTh',
      'biens',
      'services',
      'getFiliaeOfResponsable',
      'getFiltre'
    ]),
    computedGetAcheteurs() {
      let acheteurNames = this.getSettingFilialesTh.map(item => {
        return { value: item.id, text: item.name };
      });
      return acheteurNames;
    },
    computedGetVendeurs() {
      let vendeurNames = this.getFiliaeOfResponsable.map(item => {
        return { value: item.id, text: item.name };
      });
      return vendeurNames;
    },
    ListType() {
      let list = [];
      if (this.checkPermission('GBHT')) {
        list.push({ value: 'bien', text: 'Bien' });
      }
      if (this.checkPermission('GCM')) {
        list.push({ value: 'service', text: 'Service' });
      }
      return list;
    },
    computedListConfig() {
      let selected = [];
      if (
        this.configurationToAdd?.type == 'service' ||
        this.configurationToUpdate?.type == 'service'
      ) {
        return this.services.map(service => {
          return {
            value: service.id,
            text: service.nom
          };
        });
      } else if (
        this.configurationToAdd?.type == 'bien' ||
        this.configurationToUpdate?.type == 'bien'
      ) {
        return this.biens.map(bien => {
          return {
            value: bien.id,
            text: bien.nom
          };
        });
      } else {
        return selected;
      }
    },
    computedListJourCreation() {
      return [
        { text: 'Jour du création', value: null },
        { text: 'Fin du mois', value: -1 },
        { text: '01', value: 1 },
        { text: '02', value: 2 },
        { text: '03', value: 3 },
        { text: '04', value: 4 },
        { text: '05', value: 5 },
        { text: '06', value: 6 },
        { text: '07', value: 7 },
        { text: '08', value: 8 },
        { text: '09', value: 9 },
        { text: '10', value: 10 },
        { text: '11', value: 11 },
        { text: '12', value: 12 },
        { text: '13', value: 13 },
        { text: '14', value: 14 },
        { text: '15', value: 15 },
        { text: '16', value: 16 },
        { text: '17', value: 17 },
        { text: '18', value: 18 },
        { text: '19', value: 19 },
        { text: '21', value: 21 },
        { text: '22', value: 22 },
        { text: '23', value: 23 },
        { text: '24', value: 24 },
        { text: '25', value: 25 },
        { text: '26', value: 26 },
        { text: '27', value: 27 },
        { text: '28', value: 28 },
        { text: '29', value: 29 },
        { text: '30', value: 30 },
        { text: '31', value: 31 }
      ];
    }
  },
  methods: {
    ...mapActions([
      'addNewconfComptaRh',
      'checkPeriodConfig',
      'getTvas',
      'getSettingFilialeTh',
      'getServices',
      'getAllBiens',
      'getFilialsOfConnectedResponsable',
      'fillFlitre',
      'fetchAllComptaRh',
      'addUniteTva'
    ]),
    async addTav(type) {
      if (this.tvaToAdd > 100) {
        this.errorTvaUnite = 'tva doit etre inferieur à 100';
      } else if (this.tvaToAdd === '' || this.tvaToAdd === null) {
        this.errorTvaUnite = 'ce champ et obligatoire';
      } else {
        this.loadingTvaUnite = true;
        const response = await this.addUniteTva({
          valeur: parseFloat(this.tvaToAdd),
          type: type
        });
        if (response.success) {
          this.getAllTvas.push(response.response);
          this.$refs['addtvaModal'].hide();
          this.resetModalTVA();
          this.loadingTvaUnite = false;
        } else {
          this.$refs['addtvaModal'].hide();
          this.resetModalTVA();
          this.loadingTvaUnite = false;
        }
      }
    },
    resetModalTVA() {
      this.tvaToAdd = null;
      this.errorTvaUnite = null;
    },
    changeAcheteurFiltre() {
      this.fillFlitre(this.filtre);
    },
    changeVendeurFiltre() {
      this.fillFlitre(this.filtre);
    },
    changePeriodFiltre() {
      this.fillFlitre(this.filtre);
    },
    calculTTC() {
      let tva =
        Math.round(
          ((parseFloat(
            (this.configurationToAdd.montant + '').replace(',', '.') || 0
          ) *
            parseFloat(
              (this.configurationToAdd.tva + '').replace(',', '.') || 0
            )) /
            100) *
            100
        ) / 100;
      this.montant_ttc =
        Math.round(
          (parseFloat(
            (this.configurationToAdd.montant + '').replace(',', '.') || 0
          ) +
            tva) *
            100
        ) / 100;
    },
    clickMenu(menu) {
      this.selectedMenu = menu;
    },
    resetModal() {
      this.configurationToAdd = {
        type: null,
        service_bien: null,
        jour_creation: null,
        vendeur_id: null,
        acheteur_id: null,
        montant: 0,
        tva: 0,
        description: null,
        objet: null,
        date_debut: null,
        date_fin: null,
        frequence: 'mensuel'
      };
      this.loading = false;
      this.error = [];
      this.errorDuplicate = null;
      this.validateToAddconf = false;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async handleSubmitConfiguration() {
      this.loading = true;
      const response = await this.addNewconfComptaRh({
        configurationToAdd: this.configurationToAdd,
        type: this.selectedMenu
      });
      if (response.success) {
        this.loading = false;
        this.hideModal('addConfComptaRh');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    async handleErrorConfig() {
      if (
        this.configurationToAdd.type != 'service' ||
        this.configurationToAdd.type != 'bien'
      ) {
        this.disable = false;
      }
      var objToSend = {
        vendeur_id: this.configurationToAdd.vendeur_id
          ? this.configurationToAdd.vendeur_id
          : null,
        acheteur_id: this.configurationToAdd.acheteur_id
          ? this.configurationToAdd.acheteur_id
          : null,
        date_debut: this.configurationToAdd.date_debut
          ? this.configurationToAdd.date_debut
          : null,
        date_fin: this.configurationToAdd.date_fin
          ? this.configurationToAdd.date_fin
          : null,
        type: this.configurationToAdd.type,
        service_bien: this.configurationToAdd.service_bien
      };
      if (objToSend.vendeur_id != null && objToSend.acheteur_id != null) {
        const response = await this.checkPeriodConfig(objToSend);
        this.validateToAddconf = true;
        if (response?.success) {
          if (response.response) {
            this.validateToAddconf = false;
            this.errorDuplicate = null;
          }
        } else {
          this.errorDuplicate = response.error;
          this.validateToAddconf = true;
        }
      }
    },
    disabledEndDate(date) {
      return (
        this.configurationToAdd.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') <
          new Date(this.configurationToAdd.date_debut)
      );
    },
    disabledStartDate(date) {
      return (
        this.configurationToAdd.date_fin &&
        moment(date, 'YYYY-MM-DD') >= new Date(this.configurationToAdd.date_fin)
      );
    }
  },
  components: {
    filterComponent,
    Card: () => import('@/views/component/card.vue')
  },
  async mounted() {
    await this.getFilialsOfConnectedResponsable();
    await this.getTvas();
    await this.getSettingFilialeTh();
    await this.getServices();
    await this.getAllBiens();
    this.listTva = this.getAllTvas;
    if (
      this.$route.path ===
      '/setting/configurationMensuel/gestion-configuration-service-mensuel'
    ) {
      this.selectedMenu = 'service';
    } else if (
      this.$route.path ===
      '/setting/configurationMensuel/gestion-configuration-bien-mensuel'
    ) {
      this.selectedMenu = 'bien';
    } else {
      this.selectedMenu = 'client';
    }
  }
};
</script>

<style lang="scss" scoped>
.sub-menu-simple {
  .nav-bar {
    .menu-links {
      display: flex;
      align-items: center;
    }
  }
}
.button-export-style {
  height: 31px;
}
.errorConfig {
  color: red;
  font-size: 14px;
  margin: 0 auto;
  width: 524px;
  font-weight: bold;
}
.btn-add-tva-unite {
  height: 17px;
  width: 17px;
  background-color: #08a742;
  border: none;
  border-radius: 50%;
}
.btn-add-tva-unite:hover {
  background-color: #087530;
}
.icon-plus-tva-unite {
  color: #fff;
  height: 11px;
  width: 11px;
  margin-bottom: 3px;
}
</style>
