<template>
  <div class="entete">
    <div class="box-setting-header">
      <div class="title ml-4 mt-2">Configuration du paiement</div>
      <div>
        <b-button
          v-if="checkPermission('GTRHTATR')"
          v-show="selectedMenu == 'reglement'"
          variant="primary"
          class="bien-btn ml-2"
          v-b-modal.addTypeReglement
        >
          <font-awesome-icon icon="plus" /> Ajouter un type du paiement
        </b-button>
        <b-button
          v-if="checkPermission('GTRHTACP')"
          v-show="selectedMenu == 'delais'"
          variant="success"
          class="bien-btn ml-2"
          v-b-modal.addConditionPaiement
          ><font-awesome-icon icon="plus" /> Ajouter une condition du
          paiement</b-button
        >
        <b-modal
          id="addTypeReglement"
          ref="addTypeReglement"
          :hide-footer="true"
          :hide-header="true"
          no-close-on-backdrop
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap "
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter un type paiement</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('addTypeReglement')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="handleSubmitTypeReglement"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Nom"
                  label-for="nom"
                  class="input-modal-champ"
                >
                  <b-form-input
                    autocomplete="off"
                    v-model="reglementToAdd.name"
                  ></b-form-input>
                </b-form-group>
                <div class="messageError">
                  <div v-if="errorNameReglement" class="error">
                    <ul
                      v-if="Array.isArray(errorNameReglement)"
                      style="list-style-type: none"
                    >
                      <li v-for="(e, index) in errorNameReglement" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ errorNameReglement }}</div>
                  </div>
                </div>
                <div class="actionModel mt-3">
                  <div class="messageError">
                    <div v-if="errorReglement" class="error">
                      <ul
                        v-if="Array.isArray(errorReglement)"
                        style="list-style-type: none"
                      >
                        <li v-for="(e, index) in errorReglement" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ errorReglement }}</div>
                    </div>
                  </div>
                  <b-button type="submit" class="button-valide-style">
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
        <b-modal
          id="addConditionPaiement"
          ref="addConditionPaiement"
          :hide-footer="true"
          :hide-header="true"
          no-close-on-backdrop
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap "
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter un condition du paiement</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('addConditionPaiement')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="handleSubmitConditionPaiement"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Nom"
                  label-for="nom"
                  class="input-modal-champ"
                >
                  <b-form-input
                    autocomplete="off"
                    id="tyype"
                    v-model="conditionToAdd.name"
                  ></b-form-input>
                </b-form-group>
                <div class="messageError">
                  <div v-if="errorNameCondition" class="error">
                    <ul
                      v-if="Array.isArray(errorNameCondition)"
                      style="list-style-type: none"
                    >
                      <li v-for="(e, index) in errorNameCondition" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ errorNameCondition }}</div>
                  </div>
                </div>
                <b-form-group
                  label="Valeur"
                  label-for="nom"
                  class="input-modal-champ"
                >
                  <b-form-input
                    autocomplete="off"
                    id="tyype"
                    v-model="conditionToAdd.value"
                  ></b-form-input>
                </b-form-group>
                <div class="messageError">
                  <div v-if="errorValue" class="error">
                    <ul
                      v-if="Array.isArray(errorValue)"
                      style="list-style-type: none"
                    >
                      <li v-for="(e, index) in errorValue" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ errorValue }}</div>
                  </div>
                </div>
                <div id="info-wrap-condition">
                  <div class="info-column">
                    <b-form-checkbox
                      style="padding-top: 12%"
                      id="checkbox-1"
                      name="checkbox-1"
                      class="check-th-details ml-2"
                      v-model="conditionToAdd.fin_du_mois"
                    ></b-form-checkbox>
                  </div>
                  <div class="description-column">
                    <b-form-group
                      label="Fin du mois"
                      label-for="nom"
                      class="input-modal-champ"
                    >
                    </b-form-group>
                  </div>
                </div>
                <div class="actionModel mt-3">
                  <div class="messageError">
                    <div v-if="errorConditionPaiement" class="error">
                      <ul
                        v-if="Array.isArray(errorConditionPaiement)"
                        style="list-style-type: none"
                      >
                        <li
                          v-for="(e, index) in errorConditionPaiement"
                          :key="index"
                        >
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ errorConditionPaiement }}</div>
                    </div>
                  </div>
                  <b-button type="submit" class="button-valide-style">
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
    </div>

    <div class="sub-menu-simple">
      <div class="nav-bar">
        <div class="menu-links">
          <div
            class="menu-link"
            @click="clickMenu('delais')"
            v-if="checkPermission('GCPHT')"
          >
            <router-link
              class="menu-item"
              to="/setting/configuration-paiement/gestion-conditions-paiement"
            >
              Gestion des conditions paiements
            </router-link>
          </div>
          <div
            class="menu-link"
            @click="clickMenu('reglement')"
            v-if="checkPermission('GTPHT')"
          >
            <router-link class="menu-item" to="/setting/configuration-paiement">
              Gestion des types paiements
            </router-link>
          </div>
          <div v-if="LoadingReglement || LoadingCondition" class="loading ml-2">
            <div class="spinner-border loadingFetch" role="status"></div>
          </div>
        </div>
      </div>

      <div class="body">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Gestion-des-utilisateurs',
  data() {
    return {
      selectedMenu: 'reglement',
      loading: false,
      error: null,
      conditionToAdd: {
        name: null,
        value: null,
        fin_du_mois: false,
      },
      reglementToAdd: {
        name: null,
      },
    };
  },
  methods: {
    ...mapActions([
      'addNewTypeReglement',
      'addNewConditionPaiement',
      'resetMutations',
      'resetMutationsConditions',
    ]),
    resetModal() {
      this.conditionToAdd = {
        name: null,
        value: null,
        fin_du_mois: false,
      };
      this.reglementToAdd = {
        name: null,
      };
    },
    async handleSubmitTypeReglement() {
      this.loading = true;
      const response = await this.addNewTypeReglement(this.reglementToAdd);
      if (response) {
        this.loading = false;
        this.hideModal('addTypeReglement');
      } else {
        this.loading = false;
      }
    },
    async handleSubmitConditionPaiement() {
      this.loading = true;
      this.conditionToAdd.fin_du_mois =
        this.conditionToAdd.fin_du_mois == true ? 1 : 0;
      const response = await this.addNewConditionPaiement(this.conditionToAdd);
      if (response) {
        this.loading = false;
        this.hideModal('addConditionPaiement');
      } else {
        this.loading = false;
      }
    },
    clickMenu(menu) {
      this.selectedMenu = menu;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
      this.resetMutations();
      this.resetMutationsConditions();
    },
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'errorConditionPaiement',
      'errorValue',
      'errorNameCondition',
      'LoadingCondition',
      'ConditionPaiement',
      'errorNameReglement',
      'errorReglement',
      'LoadingReglement',
    ]),
    computedConditionPaiement() {
      return this.ConditionPaiement.map((item) => {
        return {
          text: item.name,
          value: item.id,
        };
      });
    },
  },
  components: {
    Card: () => import('@/views/component/card'),
  },
  mounted() {
    if (this.$route.path === '/setting/configuration-paiement') {
      this.selectedMenu = 'reglement';
    } else {
      this.selectedMenu = 'delais';
    }
  },
};
</script>
<style scoped lang="scss">
#info-wrap-condition {
  overflow: hidden;
}
.description-column {
  width: 76%;
  float: left;
}
.info-column {
  width: 10%;
  float: left;
}
</style>
<style lang="scss">
.multiselect__tag {
  background: #4d4bac;
}
.multiselect__tag-icon:after {
  color: #ffffff;
}
.box-items-permission {
  .box-item-permission {
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #4d4bac !important;
      background-color: #4d4bac !important;
    }
  }
}
</style>
